import { IInvalidParametersError } from './commonTypes';

export async function checkPaymentAuthorizeInformationService(
  resourceToken: string,
  params: ICheckPaymentAuthorizeInformationServiceRequest,
): Promise<ICheckPaymentAuthorizeInformationServiceResponse | IInvalidParametersError> {
  const headers = new Headers();
  headers.set('Content-Type', 'application/json');
  headers.set('Authorization', `bearer ${resourceToken}`);

  const requestUrl = `${process.env.REACT_APP_BFF_URL}/payment/authorize/information/check`;
  const requestOptions: RequestInit = {
    body: JSON.stringify(params),
    headers: headers,
    method: 'PUT',
  };

  const response = await fetch(requestUrl, requestOptions);

  if (response.status === 422) {
    const error = (await response.json()) as IInvalidParametersError;
    return error;
  } else if (!response.ok) {
    const serializedBody = await response.json();
    throw new Error(serializedBody);
  } else {
    const responseDto =
      (await response.json()) as ICheckPaymentAuthorizeInformationServiceResponseDto;
    const result = mapFromDto(responseDto);
    return result;
  }
}

function mapFromDto(
  resultDto: ICheckPaymentAuthorizeInformationServiceResponseDto,
): ICheckPaymentAuthorizeInformationServiceResponse {
  return { accountType: resultDto.bank_account_type };
}

export interface ICheckPaymentAuthorizeInformationServiceRequest {
  type: string;
  iban: string;
}

interface ICheckPaymentAuthorizeInformationServiceResponseDto {
  bank_account_type?: string;
}

export interface ICheckPaymentAuthorizeInformationServiceResponse {
  accountType?: string;
}
