import { ForwardedRef, forwardRef } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

const SelectField = (props: IProps, ref: ForwardedRef<HTMLInputElement>) => (
  <FormControl
    error={Boolean(props.errorMessage)}
    required={props.required}
    disabled={props.disabled}
  >
    <InputLabel id={`${props.id}Label`} required={props.required} style={props.style}>
      {props.label}
    </InputLabel>
    <Select
      inputRef={ref}
      labelId={`${props.id}Label`}
      name={props.name}
      defaultValue={''}
      required={props.required}
      value={props.value}
      style={props.style}
      onChange={props.onChange}
      label={props.label}
      error={Boolean(props.errorMessage)}
    >
      {props.items.map((item, index) => (
        <MenuItem value={item.value} key={`${props.id}-${index}`}>
          {item.description}
        </MenuItem>
      ))}
    </Select>
    {props.errorMessage && (
      <FormHelperText style={props.errorStyle}>{props.errorMessage}</FormHelperText>
    )}
  </FormControl>
);

export default forwardRef<HTMLInputElement, IProps>(SelectField);

interface IProps {
  id: string;
  name?: string;
  label: string;
  items: IItem[];
  required?: boolean;
  disabled?: boolean;
  value?: string;
  errorMessage?: string | null;
  errorStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onChange?: (event: SelectChangeEvent) => void;
}

export interface IItem {
  value: string;
  description: string;
}
