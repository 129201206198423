import { ChangeEvent, ForwardedRef, forwardRef, useState } from 'react';
import { TextField } from '@mui/material';

const IbanField = (props: IProps, ref: ForwardedRef<HTMLInputElement>) => {
  const [ibanNumber, setIbanNumber] = useState<string>('');

  const handleIbanChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const inputVal = event.target.value.replace(/ /g, ''); // Remove all the empty spaces in the input
    props.onChange?.(inputVal);

    setIbanNumber(inputVal);
  };

  return (
    <TextField
      id={props.id}
      inputRef={ref}
      name={props.name}
      label={props.label}
      value={ibanNumber}
      onChange={handleIbanChange}
      error={Boolean(props.errorMessage)}
      helperText={props.errorMessage}
      disabled={props.disabled}
      autoFocus={props.autoFocus}
      required={props.required}
      autoComplete="off"
    />
  );
};

export default forwardRef<HTMLInputElement, IProps>(IbanField);

interface IProps {
  label: string;
  id?: string;
  onChange?: (value: string) => void;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  name?: string;
  errorMessage?: string | null;
}
