import { useCallback, useEffect, useRef } from 'react';

/**
 * Creates a debounced function that delays the invocation of the original function
 * until after `wait` milliseconds have elapsed since the last time it was invoked.
 * @param {Function} callback The function to debounce.
 * @param {number} delay The number of milliseconds to delay the function execution.
 * @return {Function} The debounced function.
 */
export default function useDebounce<T>(callback: (args: T) => void, delay: number) {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(
    () => () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    },
    [],
  );

  const debouncedCallback = useCallback(
    (args: T) => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        callback(args);
      }, delay);
    },
    [callback, delay],
  );

  return debouncedCallback;
}
